import 'bootstrap'

import '@/user'
import { initPasswordToggle } from "../src/helpers/view";

require('@rails/ujs').start()

$(() => {
  if ($('body').hasClass('passwords edit')) {
    $('#user_password').on('input', function() {
      var password = $(this).val();

      if (/[A-Z]/.test(password)) {
        $('.uppercase-msg').removeClass('text-danger').addClass('text-success');
      } else {
        $('.uppercase-msg').removeClass('text-success').addClass('text-danger');
      }

      if (/[a-z]/.test(password)) {
        $('.lowercase-msg').removeClass('text-danger').addClass('text-success');
      } else {
        $('.lowercase-msg').removeClass('text-success').addClass('text-danger');
      }

      if (/\d/.test(password)) {
        $('.number-msg').removeClass('text-danger').addClass('text-success');
      } else {
        $('.number-msg').removeClass('text-success').addClass('text-danger');
      }

      if (password.length >= 8) {
        $('.8-characters-msg').removeClass('text-danger').addClass('text-success');
      } else {
        $('.8-characters-msg').removeClass('text-success').addClass('text-danger');
      }

      if(password.length == 0) {
        $('.uppercase-msg').addClass('text-danger').removeClass('text-success');
        $('.lowercase-msg').addClass('text-danger').removeClass('text-success');
        $('.number-msg').addClass('text-danger').removeClass('text-success');
        $('.8-characters-msg').addClass('text-danger').removeClass('text-success');
      }
    });

    $('#user_password_confirmation').on('input', function() {
      var confirmPassword = $(this).val();
      var password = $('#user_password').val();

      if (confirmPassword == password) {
        $('.confirm-check-msg').hide();
      } else {
        $('.confirm-check-msg').show();
      }
    });
  }

  initPasswordToggle()
})
